<template>
  <div>
    <Modal :mask-closable="false" @on-cancel="close" title="让客户重签" v-model="flag" width="400">
      <Form :model="formd" @submit.native.prevent ref="form">
        <FormItem style="margin: 0;">
          <Input :rows="3" placeholder="给客户留言" type="textarea" v-model="formd.Message" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      posting: false,
      formd: {},
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
    },
    close() {
      this.id = 0;
      this.flag = false;
      this.posting = false;
      this.formd = {};
      this.file = "";
    },
    ok() {
      this.posting = true;
      let formdata = new FormData();
      formdata.append("OrderId", this.id);
      formdata.append("Message", this.formd.Message || "");
      formdata.append("SignType", 2); // 1：上传签名的电子件，2：在线签名
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/RePushOrderSignFlow", formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>