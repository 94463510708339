<template>
  <div id="orderFlowSurvey">
    <div class="flex">
      <div class="sub-titles">调查表</div>
      <Button @click="req" icon="md-refresh" size="small" style="margin-left: 10px"></Button>
    </div>
    <div class="bk-loading-line" style="padding: 20px 0 0" v-if="loading">
      <Icon type="ios-loading"></Icon>
      <span>Loading</span>
    </div>
    <div style="margin-top:10px" v-if="survey.Id">
      <a
        :href="`/#/survey/submited?orderid=${survey.OrderId}&userid=${survey.UserId}`"
        class="text-underline"
        target="_blank"
      >{{survey.Title}}</a>
      <div class="text-sub">填表人：{{survey.UserName}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      OrderId: 0,
      survey: {},
    };
  },
  methods: {
    init(orderid) {
      this.OrderId = orderid;
      this.req();
    },
    clear() {
      this.loading = false;
      this.OrderId = 0;
      this.survey = {};
    },
    req() {
      this.loading = true;
      this.$axios
        .get("/Api/CmsSurvey/QueryByOrder", {
          params: { Id: this.OrderId },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            if (res.data[0]) {
              this.survey = res.data[0];
            }
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
#orderFlowSurvey {
  margin-bottom: 30px;
  .sub-titles {
    font-weight: bold;
    position: relative;
  }
  .sub-titles::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 2em;
    background-color: #2d8cf0;
    bottom: 0;
    left: 0;
  }
}
</style>