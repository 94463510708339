<template>
  <div>
    <Modal
      :mask-closable="false"
      @on-cancel="close"
      class="push-investigate-modal"
      title="发起满意度调查表"
      v-model="flag"
    >
      <Form :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="请选择一个问卷：" prop="SurveyId" required>
          <RadioGroup v-model="formd.SurveyId">
            <Radio :key="i" :label="a.Id" v-for="(a,i) in surveys">{{a.Title}}({{a.UpdateTime||a.CreateTime}})</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="请选择问卷填写人：" prop="SurveyUserId" required>
          <RadioGroup v-model="formd.SurveyUserId">
            <Radio :key="i" :label="a.UserId" v-for="(a,i) in users">{{a.UserName}}</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      posting: false,
      formd: {},
      rules: {
        SurveyId: [{ required: true, type: "number", message: " " }],
        SurveyUserId: [{ required: true, type: "number", message: " " }],
      },
      surveys: [],
      users: [],
    };
  },
  methods: {
    open(id) {
      this.formd.OrderId = id;
      this.flag = true;
      this.reqSurveys();
      this.reqOrderUsers();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/BtmsOrderEx/PushInvestigate", this.formd)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("发起成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    reqSurveys() {
      this.$axios
        .get("/Api/CmsSurvey/QueryPg", {
          params: { PageIndex: 1, PageSize: 99 },
        })
        .then((res) => {
          if (res.result) {
            this.surveys = res.data.DataSet || [];
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    reqOrderUsers() {
      this.$axios
        .get("/Api/BtmsOrderUser/QueryPg", {
          params: { pageIndex: 1, pageSize: 99, OrderId: this.formd.OrderId },
        })
        .then((res) => {
          if (res.result) {
            this.users = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
  },
};
</script>
<style lang="less">
.push-investigate-modal {
  .ivu-modal-body {
    max-height: 300px;
    overflow-y: scroll;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li:first-child {
    margin-top: 10px;
  }
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #dcdee2;
    padding: 5px 0;
    line-height: 22px;
    color: #2b85e4;
  }
  li .ivu-icon-md-close {
    cursor: pointer;
    font-size: 20px;
    color: #808695;
    &:hover {
      color: #ed4014;
    }
  }
  .ivu-form-item-error .ivu-radio-inner {
    border-color: #ed4014;
  }
}
</style>