<template>
  <div class="bk-page" id="orderEdit">
    <Spin class="bk-loading" fix v-if="loading">
      <div>
        <Icon type="ios-loading"></Icon>
        <div>Loading</div>
      </div>
    </Spin>
    <Spin class="bk-failed" fix v-if="failed">
      <div @click="req">
        <Icon type="md-refresh"></Icon>
        <p>Refresh</p>
      </div>
    </Spin>
    <div class="bk-page-scroll">
      <div class="flex" style="align-items: flex-start">
        <Card dis-hover>
          <Form
            :label-width="110"
            :model="formd"
            :rules="rules"
            @submit.native.prevent
            inline
            ref="form"
          >
            <FormItem label="状态：">{{ formd.StatusDescr }}</FormItem>
            <FormItem label="委托单号：" prop="Code" required>
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.Code" />
              <div v-else>{{ formd.Code }}</div>
            </FormItem>
            <FormItem label="项目工程师：" prop="PartybManagerId" required>
              <Input
                :value="formd.PartybManagerName"
                @on-clear="unPickUser('pick-party-b-manager')"
                clearable
                readonly
                v-if="$able.map.BtmsOrderUpd"
              >
                <Button @click="toPickUser('pick-party-b-manager')" icon="ios-more" slot="append"></Button>
              </Input>
              <div v-else>{{formd.PartybManagerName}}</div>
            </FormItem>
            <FormItem label="客服人员：" prop="PartybMarketerId" required>
              <Input
                :value="formd.PartybMarketerName"
                @on-clear="unPickUser('pick-party-b-marketer')"
                clearable
                readonly
                v-if="$able.map.BtmsOrderUpd"
              >
                <Button @click="toPickUser('pick-party-b-marketer')" icon="ios-more" slot="append"></Button>
              </Input>
              <div v-else>{{formd.PartybMarketerName}}</div>
            </FormItem>
            <FormItem label="单据时间：" prop="BillDate" required>
              <DatePicker
                style="width: 100%"
                v-if="$able.map.BtmsOrderUpd"
                v-model="formd.BillDate"
              />
              <div v-else>{{formd.BillDate}}</div>
            </FormItem>
            <FormItem label="实验室：" prop="LabId" required>
              <Select v-if="$able.map.BtmsOrderUpd" v-model="formd.LabId">
                <span slot="prefix">
                  <Icon class="bk-load" type="ios-loading" v-if="LabLoading"></Icon>
                </span>
                <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
              </Select>
              <div v-else>{{LabMap[formd.LabId]}}</div>
            </FormItem>
            <FormItem label="测试目的：" prop="TestPurpose" required>
              <Select style="width: 100%" v-if="$able.map.BtmsOrderUpd" v-model="formd.TestPurpose">
                <Option
                  :key="item.key"
                  :value="item.name"
                  v-for="item in $designConst.order.TestPurposeLst"
                >{{ item.name }}</Option>
              </Select>
              <div v-else>{{formd.TestPurpose}}</div>
            </FormItem>
            <FormItem label="测试标准：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.TestStandards" />
              <div v-else>{{formd.TestStandards}}</div>
            </FormItem>
            <FormItem class="long" label="测试项目：" prop="TestItems" required>
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.TestItems" />
              <div v-else>{{formd.TestItems}}</div>
            </FormItem>
            <FormItem class="long" label="测试要求：">
              <Input
                type="textarea"
                v-if="$able.map.BtmsOrderUpd"
                v-model="formd.TestRequirements"
              />
              <div v-else>{{formd.TestRequirements}}</div>
            </FormItem>
            <Divider />

            <FormItem label="委托单位：" prop="ClientId" required>
              <Select filterable v-if="$able.map.BtmsOrderUpd" v-model="formd.ClientId">
                <Option :key="item.Id" :value="item.Id" v-for="item in clientLst">{{ item.Name }}</Option>
              </Select>
              <div v-else>{{formd.ClientName}}</div>
            </FormItem>
            <FormItem label="单位地址：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.OfficeAddress" />
              <div v-else>{{formd.OfficeAddress}}</div>
            </FormItem>
            <FormItem label="送检人：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.PartyaTechPrincipal" />
              <div v-else>{{formd.PartyaTechPrincipal}}</div>
            </FormItem>
            <FormItem label="送检人电话：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.Tel" />
              <div v-else>{{formd.Tel}}</div>
            </FormItem>
            <FormItem label="送检人传真：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.Fax" />
              <div v-else>{{formd.Fax}}</div>
            </FormItem>
            <FormItem label="送检人邮箱：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.Email" />
              <div v-else>{{formd.Email}}</div>
            </FormItem>
            <Divider />

            <FormItem label="合同单位：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.ContractCorpName" />
              <div v-else>{{formd.ContractCorpName}}</div>
            </FormItem>
            <FormItem label="法定代表人：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.ContractLegalPerson" />
              <div v-else>{{formd.ContractLegalPerson}}</div>
            </FormItem>
            <FormItem label="付款联系人：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.BillingContact" />
              <div v-else>{{formd.BillingContact}}</div>
            </FormItem>
            <FormItem label="付款联系方式：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.BillingContactInfo" />
              <div v-else>{{formd.BillingContactInfo}}</div>
            </FormItem>
            <FormItem class="long" label="合同邮寄地址：">
              <Input
                placeholder="地址、收件人、邮编"
                v-if="$able.map.BtmsOrderUpd"
                v-model="formd.ContractPostAddress"
              />
              <div v-else>{{formd.ContractPostAddress}}</div>
            </FormItem>
            <FormItem class="long" label="报告邮寄地址：">
              <Input v-if="$able.map.BtmsOrderUpd" v-model="formd.ReportPostAddress" />
              <div v-else>{{formd.ReportPostAddress}}</div>
            </FormItem>
            <FormItem label="发票类型：">
              <Select v-if="$able.map.BtmsOrderUpd" v-model="formd.InvoiceType">
                <Option
                  :key="item.key"
                  :value="item.key"
                  v-for="item in $designConst.order.InvoiceTypeLst"
                >{{ item.name }}</Option>
              </Select>
              <div v-else>{{$designConst.order.InvoiceType[formd.InvoiceType]}}</div>
            </FormItem>
            <FormItem label="发票内容：">
              <Select v-if="$able.map.BtmsOrderUpd" v-model="formd.InvoiceText">
                <Option label="技术服务费" value="技术服务费"></Option>
                <Option label="检查费" value="检查费"></Option>
              </Select>
              <div v-else>{{formd.InvoiceText}}</div>
            </FormItem>
            <FormItem label="是否要报告：">
              <RadioGroup v-if="$able.map.BtmsOrderUpd" v-model="formd.Isreport">
                <Radio :label="0">
                  <Tag color="default">否</Tag>
                </Radio>
                <Radio :label="1">
                  <Tag color="blue">是</Tag>
                </Radio>
              </RadioGroup>
              <div v-else>{{formd.Isreport==1?"是":"否"}}</div>
            </FormItem>
            <FormItem label="报告中可放图片：">
              <RadioGroup v-if="$able.map.BtmsOrderUpd" v-model="formd.Ispicreport">
                <Radio :label="0">
                  <Tag color="default">否</Tag>
                </Radio>
                <Radio :label="1">
                  <Tag color="blue">是</Tag>
                </Radio>
              </RadioGroup>
              <div v-else>{{formd.Ispicreport==1?"是":"否"}}</div>
            </FormItem>
            <FormItem label="取报告方式：">
              <RadioGroup v-if="$able.map.BtmsOrderUpd" v-model="formd.ReportPostWay">
                <Radio
                  :key="item.key"
                  :label="item.name"
                  v-for="item in $designConst.order.ReportPostWayLst"
                >{{ item.name }}</Radio>
              </RadioGroup>
              <div v-else>{{$designConst.order.ReportPostWay[formd.ReportPostWay]}}</div>
            </FormItem>
            <FormItem label="样品处理方式：">
              <Select v-if="$able.map.BtmsOrderUpd" v-model="formd.PrototypeProcessWay">
                <Option
                  :key="item.key"
                  :value="item.key"
                  v-for="item in $designConst.order.PrototypeProcessWayLst"
                >{{ item.name }}</Option>
              </Select>
              <div v-else>{{$designConst.order.PrototypeProcessWay[formd.PrototypeProcessWay]}}</div>
            </FormItem>

            <FormItem class="long" label="受委托方声明：">
              <Input type="textarea" v-if="$able.map.BtmsOrderUpd" v-model="formd.Statement" />
              <div v-else>{{formd.Statement}}</div>
            </FormItem>
            <FormItem class="long" label="其他情况说明：">
              <Input
                type="textarea"
                v-if="$able.map.BtmsOrderUpd"
                v-model="formd.ClientOtherDescription"
              />
              <div v-else>{{formd.ClientOtherDescription}}</div>
            </FormItem>
          </Form>
        </Card>
        <Card dis-hover style="margin-left: 10px">
          <Timeline>
            <TimelineItem>
              <span style="margin-right: 10px">委托单签章</span>
              <Button
                @click="getOrderSignFile"
                size="small"
                type="info"
                v-if="flowbtns['ORDER-SIGN']"
              >{{ btnsText[flowbtns["ORDER-SIGN"]] }}</Button>
            </TimelineItem>
            <!-- <TimelineItem>
              <span style="margin-right: 10px">物料清单确认</span>
              <Button
                @click="pushBomSign"
                size="small"
                type="info"
                v-if="flowbtns['BOM-SIGN']"
              >{{ btnsText[flowbtns["BOM-SIGN"]] }}</Button>
            </TimelineItem> -->
            <TimelineItem>
              <span style="margin-right: 10px">排期确认</span>
              <Button
                @click="pushScheduleSign"
                size="small"
                type="info"
                v-if="flowbtns['SCHEDULE-SIGN']"
              >{{ btnsText[flowbtns["SCHEDULE-SIGN"]] }}</Button>
            </TimelineItem>
            <TimelineItem v-if="order.Status==3||order.Status==4||order.Status==5">
              <span style="margin-right: 10px">{{order.StatusDescr}}</span>
            </TimelineItem>
            <TimelineItem>
              <span style="margin-right: 10px">报告确认</span>
              <Button
                @click="pushReportSign"
                size="small"
                type="info"
                v-if="flowbtns['REPORT-SIGN']"
              >{{ btnsText[flowbtns["REPORT-SIGN"]] }}</Button>
            </TimelineItem>
            <TimelineItem>
              <span style="margin-right: 10px">样品处理确认</span>
              <Button
                @click="pushDealSign"
                size="small"
                type="info"
                v-if="flowbtns['MATERIAL-DEAL-SIGN']"
              >{{ btnsText[flowbtns["MATERIAL-DEAL-SIGN"]] }}</Button>
            </TimelineItem>
            <TimelineItem>
              <span style="margin-right: 5px">满意度调查表</span>
              <Button
                @click="pushInvestigate"
                size="small"
                type="info"
                v-if="flowbtns['INVESTIGATE-SIGN']"
              >{{ btnsText[flowbtns["INVESTIGATE-SIGN"]] }}</Button>
            </TimelineItem>
          </Timeline>
        </Card>
        <Card dis-hover style="margin-left: 10px">
          <div class="btns">
            <Button
              :loading="posting"
              @click="onOk"
              class="ok-btn"
              icon="md-checkmark"
              type="primary"
              v-if="$able.map.BtmsOrderUpd"
            >保存</Button>
            <Button
              @click="openOrderUser"
              icon="md-people"
              type="default"
              v-if="$able.map.BtmsOrderUser"
            >对接人帐号</Button>
            <Button @click="downTemp" icon="md-download">委托单签章模板</Button>
            <Button @click="openFlow" type="info">查看委托单流程</Button>
            <Button
              :loading="ending"
              @click="endOrder"
              type="error"
              v-if="$able.map.BtmsOrderUpd && formd.Status == 5"
            >结束委托单</Button>
          </div>
        </Card>
      </div>
    </div>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
    <user ref="user" />
    <flow @on-flow="req" ref="flow" />
    <push-order-sign @on-post="req" ref="PushOrderSign" />
    <push-bom-sign @on-post="req" ref="PushBomSign" />
    <push-schedule-sign @on-post="req" ref="PushScheduleSign" />
    <push-report-sign :partyaAtion="formd.FlowPartyaAction" @on-post="req" ref="PushReportSign" />
    <push-deal-sign @on-post="req" ref="PushDealSign" />
    <push-investigate @on-post="req" ref="PushInvestigate" />
  </div>
</template>
<script>
import User from "./user.vue";
import flow from "./flow";
import PickUser from "@/components/PickUser.vue";
import PushOrderSign from "@/components/PushOrderSign";
import PushBomSign from "@/components/PushBomSign";
import PushScheduleSign from "@/components/PushScheduleSign";
import PushReportSign from "@/components/PushReportSign";
import PushDealSign from "@/components/PushDealSign";
// import PushInvestigate from "@/components/PushInvestigate";
import PushInvestigate from "@/components/PushSurvey";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: {
    PickUser,
    User,
    flow,
    PushOrderSign,
    PushBomSign,
    PushScheduleSign,
    PushReportSign,
    PushDealSign,
    PushInvestigate,
  },
  mixins: [SelectLabId],
  data() {
    return {
      orderId: 0,
      loading: false,
      failed: false,
      order: {},
      formd: { Status: 0 },
      rules: {
        Code: [{ required: true, message: " " }],
        PartybManagerId: [{ required: true, type: "number", message: " " }],
        PartybMarketerId: [{ required: true, type: "number", message: " " }],
        BillDate: [
          {
            validator: (rule, value, callback) => {
              this.formd.BillDate ? callback() : callback(new Error(" "));
            },
          },
        ],
        LabId: [{ required: true, type: "number", message: " " }],
        TestPurpose: [{ required: true, message: " " }],
        TestItems: [{ required: true, message: " " }],
        ClientId: [{ required: true, type: "number", message: " " }],
      },
      clientLst: [],
      posting: false,
      action: "", // 选择人员标记
      btnsText: { push: "发起", view: "查看", sign: "确认" },
      flowbtns: {
        "ORDER-SIGN": "", // 委托单签章
        "BOM-SIGN": "", // 物料清单确认
        "SCHEDULE-SIGN": "", // 排期确认
        "REPORT-SIGN": "", // 测试报告
        "MATERIAL-DEAL-SIGN": "", // 样品处理
        "INVESTIGATE-SIGN": "", // 满意度调查表
      },
      ending: false,
    };
  },
  activated: function () {
    /**
     * 注意侧窗、弹窗控制
     */
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      // 刷新
      this.init();
    }
  },
  methods: {
    init() {
      this.orderId = this.$route.params.id;
      this.flowbtns = {
        "ORDER-SIGN": "", // 委托单签章
        "BOM-SIGN": "", // 物料清单确认
        "SCHEDULE-SIGN": "", // 排期确认
        "REPORT-SIGN": "", // 测试报告
        "MATERIAL-DEAL-SIGN": "", // 样品处理
        "INVESTIGATE-SIGN": "", // 满意度调查表
      };
      this.formd = {};
      this.order = {};
      this.$refs.form.resetFields();
      this.reqTrader(); // 客户
      this.reqLab();
      this.req();
      if (this.$refs.flow.flag) {
        this.$refs.flow.close();
      }
    },
    reqTrader() {
      this.$axios
        .get("/Api/BaseTrader/QueryPg", {
          params: { PageIndex: 1, PageSize: 99, Type: 1 },
        })
        .then((res) => {
          if (res.result) {
            this.clientLst = res.data.DataSet;
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/BtmsOrder/GetVo", { params: { Id: this.orderId } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data;
            this.order = this.$util.copy(res.data);
            if (this.formd.Status == 0 && this.$able.map.BtmsOrderSign) {
              this.$set(this.flowbtns, "ORDER-SIGN", "push");
            } else if (this.formd.Status == 1 && this.$able.map.BtmsOrderSign) {
              this.$set(this.flowbtns, "ORDER-SIGN", "sign");
            } else {
              this.$set(this.flowbtns, "ORDER-SIGN", "view");
              this.reqFlow();
            }
          } else {
            this.failed = true;
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    reqFlow() {
      // 进入这个方法，那OrderStatus便已生效
      // "ORDER-SIGN": 委托单签章
      // "BOM-SIGN": 物料清单确认
      // "SCHEDULE-SIGN": 排期确认
      // "REPORT-SIGN": 测试报告
      // "MATERIAL-DEAL-SIGN": 样品处理
      // "INVESTIGATE-SIGN": 满意度调查表
      let flows = {};
      this.$axios
        .get("/Api/BtmsFlow/QueryMin", {
          params: { OrderId: this.orderId, OnlyOrder: true },
        })
        .then((res) => {
          if (res.result) {
            for (let d of res.data) {
              flows[d.Code] = {
                Status: d.Status,
                PartyaAction: d.PartyaAction,
                PartybAction: d.PartybAction,
              };
            }
            console.log(flows);
            // 物料清单
            if (
              flows["BOM-SIGN"] == undefined &&
              this.$able.map.BtmsOrderBomSign
            ) {
              this.$set(this.flowbtns, "BOM-SIGN", "push");
            } else {
              this.$set(this.flowbtns, "BOM-SIGN", "view");
            }
            // 排期确认
            if (
              this.formd.Status == 2 &&
              flows["SCHEDULE-SIGN"] == undefined
            ) {
              this.$set(this.flowbtns, "SCHEDULE-SIGN", "push");
            } else if (flows["SCHEDULE-SIGN"]) {
              this.$set(this.flowbtns, "SCHEDULE-SIGN", "view");
            }
            // 报告确认
            if (this.order.Isreport == 1) {
              if (
                this.formd.Status == 4 &&
                flows["REPORT-SIGN"] == undefined &&
                this.$able.map.BtmsOrderReportSign
              ) {
                this.$set(this.flowbtns, "REPORT-SIGN", "push");
              } else if (flows["REPORT-SIGN"]) {
                this.$set(this.flowbtns, "REPORT-SIGN", "view");
              }
            }
            // 样品处理确认
            if (
              this.formd.Status == 4 &&
              flows["MATERIAL-DEAL-SIGN"] == undefined &&
              this.$able.map.BtmsOrderDealSign
            ) {
              this.$set(this.flowbtns, "MATERIAL-DEAL-SIGN", "push");
            } else if (flows["MATERIAL-DEAL-SIGN"]) {
              this.$set(this.flowbtns, "MATERIAL-DEAL-SIGN", "view");
            }
            // 满意度调查表
            if (
              this.formd.Status == 4 &&
              flows["INVESTIGATE-SIGN"] == undefined &&
              this.$able.map.BtmsOrderInvest
            ) {
              this.$set(this.flowbtns, "INVESTIGATE-SIGN", "push");
            } else if (flows["INVESTIGATE-SIGN"]) {
              this.$set(this.flowbtns, "INVESTIGATE-SIGN", "view");
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let param = this.$util.copy(this.formd);
      delete param.BillDate;
      if (this.formd.BillDate && typeof this.formd.BillDate == "object") {
        param.BillDate = this.formd.BillDate.format("yyyy-MM-dd");
      }
      this.$axios
        .post("/Api/BtmsOrder/Update", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("编辑成功");
            this.init();
            // this.$tab.open(this, "order", { go: 1 });
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择人员
    toPickUser(action) {
      this.action = action;
      let def = [];
      if (this.action == "pick-party-b-manager") {
        if (this.formd.PartybManagerId) {
          def = [
            {
              Id: this.formd.PartybManagerId,
              UserName: this.formd.PartybManagerName,
            },
          ];
        }
      } else if (this.action == "pick-party-b-marketer") {
        if (this.formd.PartybMarketerId) {
          def = [
            {
              Id: this.formd.PartybMarketerId,
              UserName: this.formd.PartybMarketerName,
            },
          ];
        }
      }
      this.$refs.PickUser.open(def);
    },
    // 选择人员
    onPickUser(lst) {
      console.log(lst);
      if (this.action == "pick-party-b-manager") {
        if (lst[0]) {
          this.formd.PartybManagerId = lst[0].Id;
          this.$set(this.formd, "PartybManagerName", lst[0].UserName);
        } else {
          this.formd.PartybManagerId = "";
          this.$set(this.formd, "PartybManagerName", "");
        }
      } else if (this.action == "pick-party-b-marketer") {
        if (lst[0]) {
          this.formd.PartybMarketerId = lst[0].Id;
          this.$set(this.formd, "PartybMarketerName", lst[0].UserName);
        } else {
          this.formd.PartybMarketerId = "";
          this.$set(this.formd, "PartybMarketerName", "");
        }
      }
    },
    unPickUser(action) {
      if (action == "pick-party-b-manager") {
        this.formd.PartybManagerId = "";
        this.$set(this.formd, "PartybManagerName", "");
      } else if (action == "pick-party-b-marketer") {
        this.formd.PartybMarketerId = "";
        this.$set(this.formd, "PartybMarketerName", "");
      }
    },
    // 打开登录用户
    openOrderUser() {
      this.$refs.user.open(this.orderId);
    },
    // 打开流程
    openFlow() {
      this.$refs.flow.open(this.orderId);
    },
    // 下载签章模板
    downTemp() {
      window.open("/BtmsOrderEx/GenBtmsOrderFile?id=" + this.orderId);
    },
    // 发布签章
    getOrderSignFile() {
      if (this.flowbtns["ORDER-SIGN"] == "push") {
        this.$refs.PushOrderSign.open(this.orderId);
      } else {
        this.$refs.flow.open(this.orderId, "ORDER-SIGN");
      }
    },
    // 发起物料清单确认
    pushBomSign() {
      if (this.flowbtns["BOM-SIGN"] == "push") {
        this.$refs.PushBomSign.open(this.orderId);
      } else {
        this.$refs.flow.open(this.orderId, "BOM-SIGN");
      }
    },
    // 发起排期确认
    pushScheduleSign() {
      if (this.flowbtns["SCHEDULE-SIGN"] == "push") {
        this.$refs.PushScheduleSign.open(this.orderId);
      } else {
        this.$refs.flow.open(this.orderId, "SCHEDULE-SIGN");
      }
    },
    // 发起报告确认
    pushReportSign() {
      if (this.flowbtns["REPORT-SIGN"] == "push") {
        this.$refs.PushReportSign.open(this.orderId, "push");
      } else {
        this.$refs.flow.open(this.orderId, "REPORT-SIGN");
      }
    },
    // 发起样品处理确认
    pushDealSign() {
      if (this.flowbtns["MATERIAL-DEAL-SIGN"] == "push") {
        this.$refs.PushDealSign.open(this.orderId);
      } else {
        this.$refs.flow.open(this.orderId, "MATERIAL-DEAL-SIGN");
      }
    },
    // 发起满意度调查表
    pushInvestigate() {
      if (this.flowbtns["INVESTIGATE-SIGN"] == "push") {
        this.$refs.PushInvestigate.open(this.orderId);
      } else {
        this.$refs.flow.open(this.orderId, "INVESTIGATE-SIGN");
      }
    },
    // 结束委托单
    endOrder() {
      if (confirm("是否结束委托单")) {
        this.ending = true;
        this.$axios
          .post("/Api/BtmsOrder/OrderOver", {
            Id: this.formd.Id,
          })
          .then((res) => {
            this.ending = false;
            if (res.result) {
              this.$Message.success("结束成功");
              this.init();
            } else {
              this.$Message.error(res.errormsg);
            }
          })
          .catch((err) => {
            this.ending = false;
            this.$Message.error(err.errormsg);
          });
      }
    },
  },
};
</script>
<style lang="less">
#orderEdit {
  .ivu-form .ivu-form-item-label {
    font-size: 12px;
  }
}
</style>
<style lang="less" scoped>
#orderEdit {
  position: relative;
}
.ivu-form {
  margin: 0 0px 10px 0;
  width: 600px;
}
.ivu-form-item {
  margin: 0;
  padding: 5px;
  width: 300px;
}
.ivu-form-item.long {
  width: 600px;
}
.ivu-divider-horizontal {
  margin: 15px 0 5px;
}
.btns {
  width: 160px;
  button {
    margin-top: 10px;
  }
  button:first-child {
    margin-top: 0;
  }
}
</style>