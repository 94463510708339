<template>
  <div>
    <Drawer
      class="bk-drawer order-user"
      v-model="flag"
      title="委托方对接人帐号"
      :mask-closable="false"
      @on-close="close"
      width="600"
    >
      <div class="bk-drawer-body">
        <Table
          :data="list"
          :columns="cols"
          :loading="loading"
          :row-class-name="getRowClass"
        ></Table>
        <Form
          :class="formd.Id > 0 ? 'edit' : 'add'"
          @submit.native.prevent
          :model="formd"
          :rules="rules"
          ref="form"
          inline
          :label-width="100"
        >
          <FormItem label="用户：" prop="UserId" required>
            <Input
              :value="formd.UserName"
              clearable
              @on-clear="unPickUser"
              :disabled="formd.Id > 0"
            >
              <Button
                slot="append"
                icon="ios-more"
                :disabled="formd.Id > 0"
                @click="toPickUser"
              ></Button>
            </Input>
          </FormItem>
          <FormItem label="帐号角色：" prop="Role" required>
            <Select v-model="formd.Role">
              <Option
                v-for="item in $designConst.orderUser.RoleLst"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem>
          <FormItem label="状态：" prop="Status" required>
            <Select v-model="formd.Status">
              <Option
                v-for="item in $designConst.orderUser.StatusLst"
                :key="item.key"
                :value="item.key"
                >{{ item.name }}</Option
              >
            </Select>
          </FormItem>
          <FormItem :label-width="0">
            <Button type="primary" ghost :loading="posting" @click="ok"
              >保存</Button
            >
            <Button @click="cancel">取消</Button>
          </FormItem>
        </Form>
      </div>
    </Drawer>
    <pick-user ref="PickUser" status="1" type="2" @on-ok="onPickUser" />
  </div>
</template>
<script>
import PickUser from "../../components/PickUser.vue";
export default {
  components: { PickUser },
  data() {
    return {
      id: 0,
      flag: false,
      loading: true,
      posting: false,
      list: [],
      cols: [
        { title: "用户名称", key: "UserName" },
        { title: "用户帐号", key: "UserCode" },
        {
          title: "帐号角色",
          key: "Role",
          render: (h, params) => {
            return h("span", this.$designConst.orderUser.Role[params.row.Role]);
          },
        },
        {
          title: "状态",
          key: "Status",
          render: (h, params) => {
            return h("Badge", {
              props: {
                status: this.$designConst.orderUser.StatusBadge[params.row.Status],
                text: this.$designConst.orderUser.Status[params.row.Status],
              },
            });
          },
        },
        {
          title: "操作",
          width: 100,
          render: (h, params) => {
            let a = [];
            a.push(
              h("Icon", {
                props: { type: "md-create", size: 18 },
                attrs: { title: "编辑" },
                style: { cursor: "pointer", color: "#2d8cf0" },
                on: {
                  click: () => {
                    this.formd = this.$util.copy(params.row);
                  },
                },
              })
            );
            a.push(h("Divider", { props: { type: "vertical" } }));
            a.push(
              h("Icon", {
                props: { type: "md-close", size: 18 },
                attrs: { title: "删除" },
                style: { cursor: "pointer", color: "#ed4014" },
                on: {
                  click: () => {
                    this.toDel(params.row);
                  },
                },
              })
            );
            return a;
          },
        },
      ],
      formd: { Status: 1 },
      rules: {
        UserId: [{ required: true, type: "number", message: " " }],
        Role: [{ required: true, type: "number", message: " " }],
        Status: [{ required: true, type: "number", message: " " }],
      },
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
      this.reqLst();
    },
    close() {
      this.flag = false;
      this.cancel();
    },
    cancel() {
      this.$refs.form.resetFields();
      this.formd = { Status: 1 };
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let api =
        this.formd.Id > 0
          ? "/Api/BtmsOrderUser/Update"
          : "/Api/BtmsOrderUser/Add";
      let param = this.$util.copy(this.formd);
      param.OrderId = this.id;
      this.$axios
        .post(api, param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.cancel();
            this.reqLst();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    reqLst() {
      this.cancel();
      this.loading = true;
      this.$axios
        .get("/Api/BtmsOrderUser/QueryPg", {
          params: { pageIndex: 1, pageSize: 99, OrderId: this.id },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.list = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.UserName),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/BtmsOrderUser/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqLst();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toPickUser() {
      if (this.formd.UserId) {
        this.$refs.PickUser.open([
          { Id: this.formd.UserId, UserName: this.formd.UserName },
        ]);
      } else {
        this.$refs.PickUser.open();
      }
    },
    onPickUser(lst) {
      if (lst && lst[0]) {
        this.formd.UserId = lst[0].Id;
        this.$set(this.formd, "UserName", lst[0].UserName);
      } else {
        this.formd.UserId = 0;
        this.$set(this.formd, "UserName", "");
      }
    },
    unPickUser() {
      this.formd.UserId = 0;
      this.$set(this.formd, "UserName", "");
    },
    getRowClass(row, index) {
      return row.Id == this.formd.Id ? "edit" : "";
    },
  },
};
</script>
<style lang="less">
.order-user {
  .ivu-table-row.edit td {
    background-color: #f0faff;
  }
  .ivu-form {
    padding-top: 20px;
  }
  .ivu-form.edit {
    background-color: #f0faff;
  }
  .ivu-form-item {
    width: 260px;
    margin-right: 20px;
    button + button {
      margin-left: 10px;
    }
  }
}
</style>