<template>
  <div>
    <Modal v-model="flag" title="发起样品处理确认" :mask-closable="false" @on-cancel="close" width="400">
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem label="给客户留言：" style="margin:0">
          <Input v-model="formd.Message" type="textarea" :rows="3" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Message: [{ required: true, message: " " }]
      }
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
    },
    close() {
      this.id = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
    },
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      this.$axios
        .post("/Api/BtmsOrder/PushDealSign", {
          OrderId: this.id,
          Message: this.formd.Message
        })
        .then(res => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("发起成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch(err => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    }
  }
};
</script>

