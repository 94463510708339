<template>
  <div>
    <Modal
      class="push-report-sign-modal"
      v-model="flag"
      :title="action=='push'?'发起报告确认':'上传终版报告'"
      :mask-closable="false"
      @on-cancel="close"
    >
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem label="报告文件：" prop="Files" required>
          <input
            type="file"
            ref="inputFiles"
            multiple
            @change="onFiles"
            style="display: none"
          />
          <Button
            icon="md-document"
            @click="
              () => {
                $refs.inputFiles.click();
              }
            "
            v-if="$refs.inputFiles"
            >选择文件</Button
          >
          <br style="clear: both" />
          <ul>
            <li v-for="(a, i) in files" :key="i">
              <div>{{ a.name }}</div>
              <Icon type="md-close" @click="deleteFile(i)" />
            </li>
          </ul>
        </FormItem>
        <FormItem label="给客户留言：">
          <Input v-model="formd.Message" type="textarea" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      orderId: 0,
      action: "",
      flag: false,
      posting: false,
      formd: {},
      rules: {
        Files: [{ validator: (rule, value, callback) => {
          this.files.length > 0 ? callback() : callback(new Error(" "))
        } }],
        Message: [{ required: true, message: " " }],
      },
      files: [],
    };
  },
  methods: {
    open(orderId, action) {
      this.orderId = orderId;
      this.action = action;
      this.flag = true;
    },
    close() {
      this.orderId = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.files = [];
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let formdata = new FormData();
      formdata.append("OrderId", this.orderId);
      formdata.append("Message", this.formd.Message || "");
      for (let i = 0; i < this.files.length; i++) {
        formdata.append("File", this.files[i]);
      }
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      let api = this.action == 'push' ? "/BtmsOrderEx/PushReportSignFlow" : "/BtmsOrderEx/UploadReportFinal"
      this.$axios
        .post(api, formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success(this.action == 'push' ? "发起成功" : "上传成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    onFiles(e) {
      if (e.target.files.length > 0) {
        for (let i = 0; i < e.target.files.length; i++) {
          this.files.push(e.target.files[i]);
        }
        e.target.value = ""
      }
    },
    deleteFile(i) {
      this.files.splice(i, 1)
    }
  },
};
</script>
<style lang="less">
.push-report-sign-modal {
  ul {
    margin: 0;
    padding: 0;
  }
  li:first-child {
    margin-top: 10px;
  }
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #dcdee2;
    padding: 5px 0;
    line-height: 22px;
    color: #2b85e4;
  }
  li .ivu-icon-md-close {
    cursor: pointer;
    font-size: 20px;
    color: #808695;
    &:hover {
      color: #ed4014;
    }
  }
}
</style>