<template>
  <div id="orderFlow">
    <Drawer
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer"
      title="委托单流程"
      v-model="flag"
      width="400"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="text-off" v-if="lst.length==0">暂无流程</div>
      <div
        :key="`flow-${i}`"
        @click="open2(i)"
        class="flow-item"
        dis-hover
        v-for="(item, i) in lst"
      >
        <div class="flex">
          <Icon title="左侧打开" type="md-return-left" v-if="item.target_ == '_self'" />
          <Icon title="新窗口打开" type="md-open" v-if="item.target_ == '_blank'" />
          <b>{{ item.Name }}</b>
          <span class="text-info" style="margin-left: 10px" v-if="item.ItemName">{{ item.ItemName }}</span>
          <span class="text-primary" style="margin-left: 10px">
            {{
            item.Tips
            }}
          </span>
        </div>
        <div class="flex">
          <div class="label">发起时间：</div>
          <div>
            <div>{{ item.CreateTime }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="label">委托方：</div>
          <div>
            <div class="text-primary">{{ item.PartyaActionTips }}</div>
            <div class="text-sub text-xs">{{ item.SignTimeA }}</div>
          </div>
        </div>
        <div class="flex" v-if="item.PartybActionTips">
          <div class="label">我方：</div>
          <div>
            <div class="text-primary">{{ item.PartybActionTips }}</div>
            <div class="text-sub text-xs">{{ item.SignTimeB }}</div>
          </div>
        </div>
      </div>
    </Drawer>
    <Drawer
      :title="title2"
      :transfer="false"
      @on-close="close2"
      class="bk-sub-drawer order-flow-drawer-2"
      v-model="flag2"
      width="400"
    >
      <div class="bk-drawer-body">
        <survey ref="survey" v-show="surveyFlag" />
        <attach-and-trace ref="attachAndTrace" />
      </div>
      <div
        class="bk-drawer-footer"
        v-if="
          querys.CfmOrderSignFlag ||
          querys.CfmBomSignFlag ||
          querys.PushFinalReportFlag
        "
      >
        <Button
          @click="confirmOrderSign"
          type="info"
          v-if="querys.CfmOrderSignFlag && $able.map.BtmsOrderSign"
        >确认委托单签章</Button>
        <Button
          @click="rePushOrderSign"
          type="error"
          v-if="querys.CfmOrderSignFlag && $able.map.BtmsOrderSign"
        >让客户重签</Button>
        <Button
          @click="cfmBomSign"
          type="info"
          v-if="querys.CfmBomSignFlag && $able.map.BtmsOrderBomSign"
        >物料清单签字</Button>
        <Button
          @click="pushReportSign"
          type="info"
          v-if="querys.PushFinalReportFlag && $able.map.BtmsOrderReportSign"
        >上传终版报告</Button>
      </div>
    </Drawer>
    <confirm-order-sign @on-post="onFlow" ref="ConfirmOrderSign" />
    <re-push-order-sign @on-post="onFlow" ref="RePushOrderSign" />
    <push-report-sign @on-post="onFlow" ref="PushReportSign" />
    <sign @on-ok="onSign" ref="sign" />
  </div>
</template>
<script>
import ConfirmOrderSign from "@/components/ConfirmOrderSign";
import RePushOrderSign from "@/components/RePushOrderSign";
import PushReportSign from "@/components/PushReportSign";
import Sign from "@/components/Sign";
import attachAndTrace from "../../components/attach-and-trace.vue";
import survey from "./survey.vue";
export default {
  components: {
    ConfirmOrderSign,
    RePushOrderSign,
    PushReportSign,
    Sign,
    attachAndTrace,
    survey,
  },
  data() {
    return {
      orderid: 0,
      flag: false,
      loading: false,
      failed: false,
      lst: [],
      // ——————————
      flag2: false,
      title2: "",
      querys: {},
      surveyFlag: false,
      // ——————————
      flag3: false,
      tracePosting: false,
      traceData: {},
      rule: { Content: [{ required: true, message: " " }] },
    };
  },
  methods: {
    open(id, flowCode) {
      this.orderid = id;
      this.flag = true;
      this.req(flowCode);
    },
    close() {
      this.flag2 = false;
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.lst = [];
      this.close2();
    },
    req(flowCode) {
      this.loading = true;
      this.$axios
        .get("/Api/BtmsFlow/Query", { params: { OrderId: this.orderid } })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            let l = [];
            let index = -1;
            for (let [i, d] of res.data.entries()) {
              if (flowCode && d.Code == flowCode) {
                index = i;
              }
              if (d.Code == "ORDER-SIGN") {
                // 委托单签章
                d.target_ = "_self";
              } else if (d.Code == "TESTING-SYLLABUS-SIGN") {
                // 测试大纲确认
                d.target_ = "_blank"; // to 大纲
                d.to_ = "testingSyllabus";
                d.data_ = {
                  FlowCode: d.Code,
                  OrderId: d.OrderId,
                  OrderCode: d.OrderCode,
                  ItemId: d.ItemId,
                  TestingSyllabusId: d.TestingSyllabusId,
                };
              } else if (d.Code == "TESTING-SYLLABUS-CHGING") {
                // 测试大纲变更
                d.target_ = "_blank"; // to 项目
                d.to_ = "orderItems";
                d.data_ = {
                  FlowId: d.Id,
                  FlowCode: d.Code,
                  FlowStatus: d.Status,
                  OrderId: d.OrderId,
                  OrderCode: d.OrderCode,
                  ItemId: d.ItemId,
                  ItemName: d.ItemName,
                };
              } else if (d.Code == "TESTING-SYLLABUS-SIGN-AGAIN") {
                // 测试大纲再确认
                // 只会出现在项目数据上
              } else if (d.Code == "BOM-SIGN") {
                // 物料清单确认
                d.target_ = "_blank";
                d.to_ = "bom";
                d.data_ = {
                  FlowId: d.Id,
                  FlowCode: d.Code,
                  FlowStatus: d.Status,
                  OrderId: d.OrderId,
                  OrderCode: d.OrderCode,
                  ItemId: d.ItemId,
                  ItemName: d.ItemName,
                };
              } else if (d.Code == "BOM-SIGN-AGAIN") {
                // 物料清单再次确认
              } else if (d.Code == "BOM-SEND") {
                // 物料清单寄送
              } else if (d.Code == "SCHEDULE-SIGN") {
                // 排期确认
                d.target_ = "_self";
              } else if (d.Code == "ITEM-PRESTART-SIGN") {
                // 测试项目启动确认
                d.target_ = "_blank";
                d.to_ = "orderItems";
                d.data_ = {
                  FlowId: d.Id,
                  FlowCode: d.Code,
                  FlowStatus: d.Status,
                  OrderId: d.OrderId,
                  OrderCode: d.OrderCode,
                  ItemId: d.ItemId,
                  ItemName: d.ItemName,
                };
              } else if (d.Code == "ITEM-TESTOVER-SIGN") {
                // 测试项目完成确认
                d.target_ = "_blank"; // 数据&进度侧窗
                d.to_ = "orderItems";
                d.data_ = {
                  FlowId: d.Id,
                  FlowCode: d.Code,
                  FlowStatus: d.Status,
                  OrderId: d.OrderId,
                  OrderCode: d.OrderCode,
                  ItemId: d.ItemId,
                  ItemName: d.ItemName,
                };
              } else if (d.Code == "REPORT-SIGN") {
                // 测试报告
                d.target_ = "_self";
              } else if (d.Code == "MATERIAL-DEAL-SIGN") {
                // 样品处理
                d.target_ = "_self";
              } else if (d.Code == "INVESTIGATE-SIGN") {
                // 满意度调查表
                d.target_ = "_self";
              }
              l.push(d);
            }
            this.lst = res.data;
            if (flowCode && index > -1) {
              this.open2(index);
            }
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 签章确认
    confirmOrderSign() {
      this.$refs.ConfirmOrderSign.open(this.querys.OrderId);
    },
    // 让客户再签一次
    rePushOrderSign() {
      this.$refs.RePushOrderSign.open(this.querys.OrderId);
    },
    // 受委托方确认物料清单
    cfmBomSign() {
      this.$refs.sign.open(this.querys.OrderId);
    },

    // 发起报告确认
    pushReportSign() {
      this.$refs.PushReportSign.open(this.querys.OrderId, "sign");
    },
    //
    onSign(data) {
      if (this.querys.FlowCode == "BOM-SIGN") {
        this.$axios
          .post("/Api/BtmsBom/BomConfirm", {
            OrderId: this.querys.OrderId,
            SignBase64: data[1],
          })
          .then((res) => {
            this.$refs.sign.posting = false;
            if (res.result) {
              this.$Message.success("签名成功");
              this.$refs.sign.close();
              this.onFlow();
            } else {
              this.$Message.error(res.errormsg);
            }
          })
          .catch((err) => {
            this.$refs.sign.posting = false;
            this.$Message.error(err.errormsg);
          });
      }
    },
    onFlow() {
      this.$refs.attachAndTrace.init(this.querys.OrderId, this.querys.FlowId);
      this.req(this.querys.FlowCode);
      this.$emit("on-flow");
    },
    open2(index) {
      let flow = this.lst[index];
      if (flow.target_ == "_self") {
        this.flag2 = true;
        this.title2 = flow.Name;
        this.querys = {
          FlowId: flow.Id,
          FlowCode: flow.Code,
          FlowStatus: flow.Status,
          PartyaAction: flow.PartyaAction,
          PartybAction: flow.PartybAction,
          OrderId: flow.OrderId,
          CfmOrderSignFlag:
            flow.Code == "ORDER-SIGN" &&
            flow.PartyaAction == 1 &&
            flow.PartybAction == 0,
          CfmBomSignFlag:
            flow.Code == "BOM-SIGN" &&
            flow.PartyaAction == 1 &&
            flow.PartybAction == 0,
          PushFinalReportFlag:
            flow.Code == "REPORT-SIGN" &&
            flow.PartyaAction == 1 &&
            flow.PartybAction == 0,
        };
        if (flow.Code == "INVESTIGATE-SIGN") {
          this.$refs.attachAndTrace.init(flow.OrderId, flow.Id, {
            attachFlag: false,
            tracAddFlag: false,
          });
          this.surveyFlag = true;
          this.$refs.survey.init(flow.OrderId);
        } else if (flow.Code == "MATERIAL-DEAL-SIGN") {
          this.$refs.attachAndTrace.init(flow.OrderId, flow.Id, {
            attachFlag: false,
          });
          this.surveyFlag = false;
          this.$refs.survey.clear();
        } else {
          this.$refs.attachAndTrace.init(flow.OrderId, flow.Id, {
            delAttachFlag: this.$able.map.BtmsOrderDelFlowAtta
          });
          this.surveyFlag = false;
          this.$refs.survey.clear();
        }
      } else if (flow.target_ == "_blank") {
        this.$tab.open(this, flow.to_, { go: 1, re: 1 });
        window.toTabData = flow.data_;
      }
    },
    close2() {
      this.flag2 = false;
      this.$refs.attachAndTrace.clear();
    },
  },
};
</script>
<style lang="less" scoped>
.bk-drawer-body {
  padding-top: 20px;
}
.flow-item {
  position: relative;
  border-bottom: 1px dashed #e8eaec;
  margin-bottom: 10px;
  cursor: pointer;
  .flex {
    margin-bottom: 10px;
  }
  .label {
    width: 5em;
    text-align: right;
  }
  .ivu-icon-md-return-left,
  .ivu-icon-md-open {
    position: absolute;
    top: 0;
    right: -12px;
    color: #c5c8ce;
  }
}
</style>
<style lang="less">
.order-flow-drawer-2 {
  .ivu-drawer-mask {
    right: 400px;
    background-color: transparent;
  }
  .ivu-drawer-wrap {
    right: 400px;
    border-right: 1px solid #e8eaec;
  }
}
</style>

