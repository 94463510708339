<template>
  <div id="PushOrderSign">
    <Modal
      v-model="flag"
      title="发起委托单签章"
      :mask-closable="false"
      @on-cancel="close"
    >
      <Form @submit.native.prevent :model="formd" :rules="rules" ref="form">
        <FormItem label="委托单文件：" prop="File" required>
          <Upload action="" :before-upload="onFile">
            <Button icon="md-document">选择文件</Button>
          </Upload>
          <div v-if="file">{{ file.name }}</div>
        </FormItem>
        <FormItem label="给客户留言：">
          <Input v-model="formd.Message" type="textarea" />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" :loading="posting" @click="ok">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      posting: false,
      formd: {},
      rules: {
        File: [
          {
            validator: (rule, value, callback) => {
              this.file ? callback() : callback(new Error("请选择文件"));
            },
          },
        ],
        Message: [{ required: true, message: " " }],
      },
      file: "",
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
    },
    close() {
      this.id = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = {};
      this.file = "";
    },
    onFile(file) {
      this.file = file;
      return false;
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let formdata = new FormData();
      formdata.append("OrderId", this.id);
      formdata.append("Message", this.formd.Message || "");
      formdata.append("File", this.file);
      formdata.append("SignType", 2); // 1：上传签名的电子件，2：在线签名
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/PushOrderSignFlow", formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("发起成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>