<template>
  <div>
    <Modal :mask-closable="false" @on-cancel="close" title="发起排期确认" v-model="flag">
      <Form :model="formd" :rules="rules" @submit.native.prevent ref="form">
        <FormItem label="排期文件：" prop="File" required>
          <Upload :before-upload="onFile" action>
            <Button icon="md-document">选择文件</Button>
          </Upload>
          <div v-if="file">{{ file.name }}</div>
        </FormItem>
        <FormItem label="需要客户签字确认：">
          <RadioGroup v-model="formd.AutoCheck">
            <Radio label="0">是</Radio>
            <Radio label="1">否</Radio>
          </RadioGroup>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button :loading="posting" @click="ok" type="primary">确定</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      flag: false,
      posting: false,
      formd: { AutoCheck: 0 },
      rules: {
        File: [
          {
            validator: (rule, value, callback) => {
              this.file ? callback() : callback(new Error("请选择文件"));
            },
          },
        ],
        Message: [{ required: true, message: " " }],
      },
      file: "",
    };
  },
  methods: {
    open(id) {
      this.id = id;
      this.flag = true;
    },
    close() {
      this.id = 0;
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = { AutoCheck: 0 };
      this.file = "";
    },
    onFile(file) {
      this.file = file;
      return false;
    },
    ok() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let formdata = new FormData();
      formdata.append("OrderId", this.id);
      formdata.append("File", this.file);
      formdata.append("AutoCheck", this.formd.AutoCheck);
      let config = { headers: { "Content-Type": "multipart/form-data" } };
      this.$axios
        .post("/BtmsOrderEx/PushScheduleSignFlow", formdata, config)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("发起成功");
            this.close();
            this.$emit("on-post");
          } else {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>